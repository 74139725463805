import React, { useState } from 'react'

import Card, { CompareLines } from '@cms/events/pages/Events/Card'
import { StarOutlined } from '@ant-design/icons'
import { Col, Empty, Row, Select } from 'antd'
import { GET_SALES_BY_PAYMENT_TYPE_FINANCIAL } from '@cms/events/graphql/queries/financial'
import { useQuery } from '@apollo/client'
import { formatMoneyReal } from '../utils'

export default function FinancialPayments({ eventId, dateRange }) {
    const [type, setType] = useState('numeric')
    const [numericType, setNumericType] = useState('reais')

    const { data } = useQuery(GET_SALES_BY_PAYMENT_TYPE_FINANCIAL, {
        variables: { eventId, ...dateRange, status: 'paid' }
    })

    const paymentType = {
        pix: 'Pix',
        credit_card: 'Cartão de crédito'
    }

    const values =
        data?.salesByPaymentType?.map((sales) => ({
            name: paymentType[sales.type] || 'Outros',
            percent: `${sales.percentage}%`,
            value:
                type === 'numeric'
                    ? numericType === 'total'
                        ? sales.count
                        : `R$${formatMoneyReal(sales?.total)}`
                    : `${sales.percentage}%`
        })) || []

    return (
        <Card
            icon={StarOutlined}
            title="Formas de pagamento"
            subTitle="(Quantidade transações)"
            options={
                <Row gutter={[12, 12]}>
                    <Col>
                        <Select
                            placeholder="Selecione"
                            value={type}
                            onChange={(value) => setType(value)}
                            style={{ minWidth: '150px' }}
                        >
                            <Select.Option value="numeric">
                                Valor númerico
                            </Select.Option>
                            <Select.Option value="percent">
                                Parcentual
                            </Select.Option>
                        </Select>
                    </Col>
                    {type === 'numeric' ? (
                        <Col>
                            <Select
                                placeholder="Selecione"
                                value={numericType}
                                onChange={(value) => setNumericType(value)}
                                style={{ minWidth: '180px' }}
                            >
                                <Select.Option value="reais">
                                    Valor em Reais
                                </Select.Option>
                                <Select.Option value="total">
                                    Quantidade de transações
                                </Select.Option>
                            </Select>
                        </Col>
                    ) : null}
                </Row>
            }
        >
            {values.length > 0 ? (
                <CompareLines title="Formas de pagamento" values={values} />
            ) : (
                <Empty description="Não há dados" />
            )}
        </Card>
    )
}

