import { ITreeData } from '../components/SelectTree/types'
import { IWatchCategory, IWatchCourse } from './types'

export const getWatchCategoryTreeData = (
    items: IWatchCategory[]
): ITreeData[] => {
    const convert = (
        item: IWatchCategory[],
        parent?: IWatchCategory
    ): ITreeData[] => {
        return item
            ?.filter((item) => item.parent?.id === parent?.id)
            ?.map((_item) => ({
                title: _item.name,
                label: _item.name,
                value: _item.id,
                key: _item.id,
                parent: _item.parent?.id,
                children: convert(items, _item)
            }))
    }

    return convert(items)
}

export const getWatchCourseTreeData = (items: IWatchCourse[]): ITreeData[] => {
    const convert = (item: IWatchCourse[]): ITreeData[] => {
        return item?.map((_item) => ({
            title: _item.title,
            label: _item.title,
            value: _item.id,
            key: _item.id,
            children: []
        }))
    }

    return convert(items)
}

