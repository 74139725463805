import { HeaderSections } from './Header'
import { HeroSections } from './Hero'
import { ContentSections } from './Content'
import { CategoriesSections } from './Categories'
import { ContentByCategorySections } from './ContentByCategory'
import { ExpertsSections } from './Experts'
import { CoursesSections } from './Courses'

export default {
    ...HeaderSections,
    ...HeroSections,
    ...ContentSections,
    ...ContentByCategorySections,
    ...ExpertsSections,
    ...CategoriesSections,
    ...CoursesSections
}
